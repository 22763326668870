<template>
  <section>
    <!-- breadcrumb -->
    <div class="breadcrumb-top">
      <b-breadcrumb class="">
        <b-breadcrumb-item :to="{ name: 'dashboard' }">
          <feather-icon
            icon="HomeIcon"
            size="16"
          />
        </b-breadcrumb-item>
        <b-breadcrumb-item>
          {{ $t('User') }}
        </b-breadcrumb-item>
        <b-breadcrumb-item :to="{ name: 'role-list' }" >
          {{ $t('Role') }}
        </b-breadcrumb-item>
        <b-breadcrumb-item active >
          {{$route.params.roleId}}
        </b-breadcrumb-item>
      </b-breadcrumb>
    </div>
    <!-- back button -->
    <b-button
      variant="flat-secondary"
      class="ll-cancel"
      :to="{ name: 'role-list' }"
    >
      <b-img
        :src="require('@/assets/images/imgs/return.png')"
        height="auto"
        class="mr-1"
      />
    </b-button>
    <!-- base info -->
    <b-card no-body>
      <b-card-header>
        <b-card-title>{{ $t('Role') }}</b-card-title>
        <div class="ll-required">* {{ $t('Required Fields') }}</div>
      </b-card-header>
      <b-card-body class="ll-cbody">
        <b-row>
          <b-col md='4' v-if="false">
            <b-form-group
              class="ll-bform"
              label="Organization"
              label-for="org"
            >
              <v-select
                id="org"
                :options="orgList"
                label="name"
                @input="getOrgId"
                v-model="org"
              >
              </v-select>
            </b-form-group>
          </b-col>
          <b-col md='4'>
            <b-form-group
              class="ll-bform"
              label=""
              label-for="roleName"
            >
              <label class="ll-boldText">{{ $t('Role Name') }}</label>
              <b-form-input
                id="roleName"
                v-model="roleInfo.name"
              />
            </b-form-group>
          </b-col>
          <b-col md='4'>
            <b-form-group
              class="ll-bform"
              :label="$t('Role Description')"
              label-for="roleDes"
            >
              <b-form-input
                id="roleDes"
                v-model="roleInfo.description"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-card no-body>
      <b-card-header>
        <b-card-title>{{ $t('Permission') }}</b-card-title>
      </b-card-header>
      <b-card-body class="ll-cbody">
        <vxe-table
          resizable
          row-id="id"
          :data="permissList"
          :tree-config="{children: 'children'}"
          :checkbox-config="{labelField: 'name', checkRowKeys: JSON.parse(JSON.stringify(checkRowKeysList)) }"
          @checkbox-change="selectChangeEvent"
          @checkbox-all="allSelect"
          ref="table"
          empty-text="No matching records found">
          <vxe-table-column type="checkbox" width="400" tree-node></vxe-table-column>
          <vxe-table-column field="id" title="id"></vxe-table-column>
          <vxe-table-column field="routerPath" :title="$t('RouterPath')"></vxe-table-column>
          <vxe-table-column field="type" :title="$t('Type')"></vxe-table-column>
          <vxe-table-column field="description" :title="$t('Description')"></vxe-table-column>
          <!-- <vxe-table-column field="view" title="View">
           <template #default="{ row }">
              <b-form-checkbox
                :checked="row.view"
                v-model="row.view"
              />
            </template>
          </vxe-table-column>
          <vxe-table-column field="edit" title="Edit">
           <template #default="{ row }">
              <b-form-checkbox
                :checked="row.edit"
                v-model="row.edit"
              />
            </template>
          </vxe-table-column>
          <vxe-table-column field="export" title="Export">
           <template #default="{ row }">
              <b-form-checkbox
                :checked="row.export"
                v-model="row.export"
              />
            </template>
          </vxe-table-column> -->
        </vxe-table>
      </b-card-body>
    </b-card>
    <div class="ll-subBtn">
      <b-button
        variant="info"
        @click="updateRole"
      >
        {{ $t('Submit') }}
      </b-button>
    </div>
  </section>
</template>

<script>
import {
  BBreadcrumb,
  BBreadcrumbItem,
  BCard,
  BCardBody,
  BCardHeader,
  BCardTitle,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BFormCheckbox,
  BImg,
} from 'bootstrap-vue'
// import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
// import { required } from '@validations'

export default {
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BCard,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    // ValidationProvider,
    // ValidationObserver,
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    vSelect,
    BFormCheckbox,
    BImg,
  },
  data() {
    return {
      // required,
      roleName: '',
      role: '',
      orgList: [],
      org: '',
      roleId: '',
      pCondition: {
        create: null,
        read: null,
        update: null,
        delete: null,
        diyModuleName: '',
        routerPathString: '',
        name: '',
        id: '',
      },
      roleInfo: {
        id: '',
        name: '',
        orgId: '',
        description: '',
        permissionIds: [],
      },
      permissList: [],
      checkboxConfig: {
        labelField: 'name',
        checkRowKeys: null,
      },
      checkRowKeysList: [],
    }
  },
  computed: {
  },
  created() {
    this.getRoleDetail()
    this.queryPerm()
    // this.getOrganization()
  },
  mounted() {
    // setTimeout(() => {
    //   this.getRoleDetail()
    // }, 100)
  },
  methods: {
    selectChangeEvent ({ records }) {
      const arr = []
      records.map(i => {
        arr.push(i.id)
      })
      this.roleInfo.permissionIds = arr
    },
    allSelect({ records }) {
      const arr = []
      records.map(i => {
        arr.push(i.id)
      })
      this.roleInfo.permissionIds = arr
    },
    // 初始化权限列表
    queryPerm() {
      this.$http.get('admin/settings/permission/getList').then(res => {
        this.permissList = res.data.data
      })
    },
    // 数组内字符串转数字类型
    turnNum(nums) {
      return nums.map(Number)
    },
    // get roleinfo
    getRoleDetail() {
      const roleInfo = this.$route.params.roleInfo
      if (roleInfo) {
        this.roleInfo.name = roleInfo.name
        this.org = roleInfo.orgName
        this.roleInfo.description = roleInfo.description
        if (sessionStorage.getItem('indeterminates') === null ){
          this.checkRowKeysList = JSON.parse(JSON.stringify(roleInfo.permissionIds))
        } else {
          const arr4 = sessionStorage.getItem('indeterminates').split(',')
          const arr5 = this.turnNum(arr4)
          this.checkRowKeysList = JSON.parse(JSON.stringify(roleInfo.permissionIds.filter(x => !arr5.includes(x))))
        }
      } else {
        this.getRoleDetail1()
      }
    },
    getRoleDetail1() {
      this.$http.get('admin/settings/role/getInfoById', { params: { id: this.$route.params.roleId }}).then(res =>{
        const info = res.data.data
        this.roleInfo.name = info.name
        // this.roleInfo.orgId = info.orgId
        this.org = info.orgName
        this.roleInfo.description = info.description
        // this.checkboxConfig.checkRowKeys = JSON.parse(JSON.stringify(info.permissionIds))
        if (sessionStorage.getItem('indeterminates') === null ){
          // setTimeout(() => {
          //   this.checkRowKeysList = JSON.parse(JSON.stringify(info.permissionIds))
          // }, 10)
          this.checkRowKeysList = JSON.parse(JSON.stringify(info.permissionIds))
          // console.log('1', this.checkRowKeysList)
        } else {
          const arr4 = sessionStorage.getItem('indeterminates').split(',')
          const arr5 = this.turnNum(arr4)
          this.checkRowKeysList = JSON.parse(JSON.stringify(info.permissionIds.filter(x => !arr5.includes(x))))
        }
      })
    },
    // update role
    updateRole() {
      const $table = this.$refs.table
      // console.log($table)
      const arr = []
      const arr1 = []
      $table.selection.map(i =>{
        if (i.id > 0) {
          arr.push(i.id)
        }
        if (i.parentId > 0) {
          arr1.push(i.parentId)
        }
      })
      const arr2 = arr.concat(arr1)
      this.roleInfo.permissionIds = [...new Set(arr2)]
      // 不完全选择一级菜单
      const arr3 = []
      $table.treeIndeterminates.map(i => {
        arr3.push(i.id)
      })
      sessionStorage.setItem('indeterminates', arr3)
      this.roleInfo.id = this.$route.params.roleId
      this.$http.put('admin/settings/role/updateRole', this.roleInfo).then(res =>{
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Edit Success',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        this.$router.push({ name: 'role-list' })
      })
    },
    // 查询组织信息
    getOrganization() {
      this.$http.get('/admin/settings/organization/findAllOrganization').then(res => {
        this.orgList = res.data.data
      })
    },
    // 获取组织ID
    getOrgId(id) {
      this.roleInfo.orgId = id.id
      this.org = id.name
    },
  },
}
</script>
<style scoped>
.ll-subBtn{
  text-align: right;
  height: 50px;
}
</style>
